import React from 'react'
import './style.css'

const SliderList = function ({ data }) {

	if (data.length > 5) {
		return (
			data.map((item, index) => {
				if (index < 4) {
					if (index !== 0) {
						return (<div
							key={index}
							className='slider__page'
						>
							<div className='slider__image'>
								<img src={item.url} alt="" />
							</div>
							<div className='slider__btn'>
							</div>
						</div>)
					} else {
						return (<div
							key={index}
							className='slider__page'
						>
							<div className='slider__image'>
								<img src={item.url} alt="" />
							</div>
							<div className='slider__image-bg'>
								<img src={item.url} alt="" />
							</div>
							<div className='slider__btn'>
							</div>
						</div>)
					}
				} else if (index === 4) {
					return (<div
						key={index}
						className='slider__page'
					>
						<div className='slider__image'>
							<div className='slider__much-pics'>
								<div className='slider__much-pics-info'>
									<svg xmlns="http://www.w3.org/2000/svg" width="102" height="92" viewBox="0 0 102 92" fill="none">
										<path d="M91 11H75.15L66 1H36L26.85 11H11C8.34783 11 5.8043 12.0536 3.92893 13.9289C2.05357 15.8043 1 18.3478 1 21V81C1 83.6522 2.05357 86.1957 3.92893 88.0711C5.8043 89.9464 8.34783 91 11 91H91C93.6522 91 96.1957 89.9464 98.0711 88.0711C99.9464 86.1957 101 83.6522 101 81V21C101 18.3478 99.9464 15.8043 98.0711 13.9289C96.1957 12.0536 93.6522 11 91 11ZM51 26C44.3696 26 38.0107 28.6339 33.3223 33.3223C28.6339 38.0107 26 44.3696 26 51C26 57.6304 28.6339 63.9893 33.3223 68.6777C38.0107 73.3661 44.3696 76 51 76C57.6304 76 63.9893 73.3661 68.6777 68.6777C73.3661 63.9893 76 57.6304 76 51C76 44.3696 73.3661 38.0107 68.6777 33.3223C63.9893 28.6339 57.6304 26 51 26Z" stroke="white" strokeWidth="2" strokeLinejoin="round" />
									</svg>
									<div className='slider__pics-count'>{`еще ${data.length - 4} фото`}</div>
								</div>
							</div>
							<img src={item.url} alt="" />
						</div>
						<div className='slider__btn'>
						</div>
					</div>)
				}
			})
		)
	} else {
		return (
			data.map((item, index) => {
				if (index !== 0) {
					return (<div
						key={index}
						className='slider__page'
					>
						<div className='slider__image'>
							<img src={item.url} alt="" />
						</div>
						<div className='slider__btn'>
						</div>
					</div>)
				} else {
					return (<div
						key={index}
						className='slider__page'
					>
						<div className='slider__image'>
							<img src={item.url} alt="" />
						</div>
						<div className='slider__image-bg'>
							<img src={item.url} alt="" />
						</div>
						<div className='slider__btn'>
						</div>
					</div>)
				}
			})
		)
	}


}

export default function Slider(props) {

	const {
		children,
		...rest
	} = props

	// console.log(children);
	// console.log(children?.length);

	return (
		<div
			{...rest}
			className='slider'
		>
			<SliderList data={children} />
		</div>
	)
}
