import React from 'react'
import './style.css'

export default function PageContainer(props) {
	const {
		children,
		...rest
	} = props
	return (
		<div
			className='main-wrapper'
			{...rest}
		>
			{children}
			<div className='bg-image d-none d-lg-block'>
				<img src="/img/bg-image.svg" alt="" />
			</div>
			<div className='bg-image d-lg-none'>
				<img src="/img/bg-image-mob.svg" alt="" />
			</div>
		</div>
	)
}
