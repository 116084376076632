import React, { useState, useCallback, useEffect } from 'react'
import Header from 'components/Header/Header'
import PageContainer from 'components/PageContainer/PageContainer'
import Footer from 'components/Footer/Footer'
import OurCars from './OurCarsBlocks/OurCars/OurCars'
import CarCard from './OurCarsBlocks/CarCard/CarCard'
import ShowMore from './OurCarsBlocks/ShowMore/ShowMore'
import WhyWe from 'components/WhyWe/WhyWe'
import { getCarsList } from 'services/cars.services'
import { debounce } from 'lodash'
import './style.css'
import SkeletonCarCard from 'components/Preloaders/Skeleton/SkeletonCarCard'

export default function OurCarsPage() {

	const [carsList, setCarsList] = useState([]);
	const [storageList, setStorageList] = useState([]);
	const [page, setPage] = useState(1);
	const [showMore, setShowMore] = useState(false);
	const [updateStorage, setUpdateStorage] = useState(false);
	const [sortType, setSortType] = useState({
		sort: 'sort_index',
		direction: 'asc'
	})
	const [isLoading, setIsLoading] = useState(true);


	const getCarsData = useCallback(debounce(async (sort, direction, page) => {
		try {
			const { data: result } = await getCarsList(sort, direction, page);
			console.log(result.data);
			if (result.last_page > result.current_page) {
				setShowMore(true);
			} else {
				setShowMore(false);
			}
			setCarsList(result.data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}, 400), [])

	useEffect(() => {
		setIsLoading(true);
		setPage(1);
		setStorageList([]);
		getCarsData(sortType.sort, sortType.direction, page);
		if (updateStorage) {
			localStorage.setItem('sortType', JSON.stringify(sortType));
		}
	}, [sortType])

	useEffect(() => {
		getCarsData(sortType.sort, sortType.direction, page);
	}, [page])


	useEffect(() => {
		setStorageList((storageList) => [...storageList, carsList]);
	}, [carsList])

	useEffect(() => {
		window.scrollTo(0, 0);
		if (localStorage.getItem("sortType") !== null) {
			setSortType(JSON.parse(localStorage.getItem('sortType')));
			console.log(JSON.parse(localStorage.getItem('sortType')));
			setUpdateStorage(true);
		} else {
			console.log('nope');
			setUpdateStorage(true);
		}
	}, [])

	return (
		<PageContainer>
			<Header activeLink={2} />
			<OurCars setSortType={setSortType} />
			{isLoading ? <>
				<SkeletonCarCard />
				<SkeletonCarCard />
				<SkeletonCarCard />
			</> :
				storageList.map(carsList => carsList?.map((item, index) =>
					<CarCard
						key={index}
						data={item}
					/>
				))
			}
			{showMore ? <ShowMore page={page} setPage={setPage} /> : ''}
			<WhyWe />
			<Footer />
		</PageContainer>
	)
}
