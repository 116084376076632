import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from "./pages/MainPage/MainPage";
import OurCarsPage from "pages/OurCarsPage/OurCarsPage";
import ContactsPage from "pages/ContactsPage/ContactsPage";
import CarPage from "pages/CarPage/CarPage";

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path='/' element={<MainPage />} />
					<Route path='/ourcars' element={<OurCarsPage />} />
					<Route path='/cars/:carId' element={<CarPage />} />
					<Route path='/contacts' element={<ContactsPage />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
