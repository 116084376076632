import React, { useEffect, useState } from 'react'
import Header from 'components/Header/Header'
import PageContainer from 'components/PageContainer/PageContainer'
import Footer from 'components/Footer/Footer'
import WhyWe from 'components/WhyWe/WhyWe'
import { CarPageCarInfo } from './CarPageBlocks/CarPageBlocks'
import Popup from 'components/Popup/Popup'
import Preloader from 'components/Preloaders/Preloader/Preloader'

export default function CarPage() {
	useEffect(() => window.scrollTo(0, 0), [])
	const [popupData, setPopupData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	// useEffect(() => console.log(isLoading), [isLoading])

	return (
		<PageContainer>
			{isLoading ? <Preloader />: null}
			<Header activeLink={2} />
			<CarPageCarInfo
				setPopupData={setPopupData}
				setIsLoading={setIsLoading}
			/>
			<WhyWe />
			<Footer />
			{popupData ? <Popup data={popupData} /> : null}
		</PageContainer>
	)
}