import React from 'react'
import Skeleton from './Skeleton'
import './style.css'

export default function SkeletonCarCard() {
	return (
		<div className='skeleton-car-card'>
			<div
				className='car-card'
			>
				<div className='skeleton-slider'>
					<Skeleton />
				</div>
				<div className='car-card__info'>
					<div className='car-card__model'>
						<Skeleton />
					</div>
					<div className='car-card__description'>
						<div className='car-card__line'>
							<Skeleton />
						</div>
						<div className='car-card__line'>
							<Skeleton />
						</div>
						<div className='car-card__line'>
							<Skeleton />
						</div>
						<div className='car-card__line'>
							<Skeleton />
						</div>
						<div className='car-card__line'>
							<Skeleton />
						</div>
						<div className='car-card__line'>
							<Skeleton />
						</div>
					</div>
					<div className='car-card__bottom'>
						<div className='skeleton-car-card-footer1'>
							<Skeleton />
						</div>
						<div className='skeleton-car-card-footer2'>
							<Skeleton />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
