import React from 'react'
import './style.css'
import MapBasics from '../ContactsPageMap/ContactsPageMap'

export default function Contacts() {
	return (
		<div className='contacts'>
			<div className='contacts__info'>
				<h1>Контакты</h1>
				<div className='contacts__info-row'>
					<div className='contacts__header'>
						Адрес
					</div>
					<div className='contacts__data'>
						117461, г. Москва, ул. Каховка, д. 30
					</div>
				</div>
				<div className='contacts__info-row'>
					<div className='contacts__header'>
						Способы связи
					</div>
					<div className='contacts__data'>
						<div className='contacts__phone'>
							<h6 className='contacts__sub-header'>Телефон</h6>
							<a className='contacts__phone-number' href='tel:+74993837575'>+7 499 383 75 75</a>
						</div>
						<div className='contacts__email'>
							<h6 className='contacts__sub-header'>E-mail</h6>
							<a className='contacts__email-address' href='mailto:info@autobrif.ru'>info@autobrif.ru</a>
						</div>
					</div>
				</div>
				<div className='contacts__info-row'>
					<div className='contacts__header'>
						Социальные сети
					</div>
					<div className='contacts__data'>
						<a className='contacts__data-socials' href='https://t.me/Autobrif' target='_blank'>
							Telegram
						</a>
						<a className='contacts__data-socials' href='http://wa.me/74993837575' target='_blank'>
							Whatsapp
						</a>
					</div>
				</div>
			</div>
			<div className='contacts__map'>
				<div className='contacts__map-wrap'>
					<MapBasics />
				</div>
			</div>
		</div>
	)
}