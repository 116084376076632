import React from "react"
import './style.css'

export default function WhyWe() {
	return (
		<div className='why-we'>
			<div className='why-we__caption-block'>
				<h2>Почему<br />выбирают&nbsp;нас</h2>
				<div className='why-we__caption'>
					Все транспортные средства проверены на&nbsp;предмет юридической чистоты (номера кузова, рамы, шасси, модели и&nbsp;номера двигателя, маркировочных обозначений, достоверности документов, наличия судебных исполнительных производств, таможенных ограничений, залогов, кредитов). Также осуществляем проверку дополнительных рисков при ввозе&nbsp;ТС из&#8209;за рубежа.
				</div>
			</div>
			<div className='why-we__list'>
				<div className='why-we__list-item'>
					<div className='why-we__list-item-number'>
						01
					</div>
					<div className='why-we__list-item-text'>
						Отсутствие юридических и&nbsp;финансовых&nbsp;рисков при&nbsp;покупке&nbsp;авто
					</div>
				</div>
				<div className='why-we__list-item'>
					<div className='why-we__list-item-number'>
						02
					</div>
					<div className='why-we__list-item-text'>
						Предоставление широкого спектра услуг
					</div>
				</div>
				<div className='why-we__list-item'>
					<div className='why-we__list-item-number'>
						03
					</div>
					<div className='why-we__list-item-text'>
						Профессионализм и&nbsp;порядочность команды
					</div>
				</div>
				<div className='why-we__list-item'>
					<div className='why-we__list-item-number'>
						04
					</div>
					<div className='why-we__list-item-text'>
						Индивидуальный подход к&nbsp;каждому клиенту
					</div>
				</div>
			</div>
		</div>
	)
}