import React from 'react'
import './style.css'

export default function Button(props) {
	const {
		variant,
		offsetClass,
		title,
		leftIcon,
		rightIcon,
		...rest
	} = props

	let classString = '';
	if (variant) {
		if (leftIcon) {
			if (offsetClass) {
				classString = `button ${variant} ${offsetClass} btn-left-icon`;
			}
			else {
				classString = `button ${variant} btn-left-icon`;
			}
		} else if (rightIcon) {
			if (offsetClass) {
				classString = `button ${variant} ${offsetClass} btn-right-icon`;
			}
			else {
				classString = `button ${variant} btn-right-icon`;
			}
		} else {
			if (offsetClass) {
				classString = `button ${variant} ${offsetClass}`;
			}
			else {
				classString = `button ${variant}`;
			}
		}


	} else if (leftIcon) {
		if (offsetClass) {
			classString = `button ${offsetClass} btn-left-icon`;
		}
		else {
			classString = `button btn-left-icon`;
		}
	} else if (rightIcon) {
		if (offsetClass) {
			classString = `button ${offsetClass} btn-right-icon`;
		}
		else {
			classString = `button btn-right-icon`;
		}
	} else if (offsetClass) {
		classString = `button ${offsetClass}`;
	} else {
		classString = 'button';
	}

	return (
		<button
			{...rest}
			className={classString}
		>
			{leftIcon}
			<span>{title}</span>
			{rightIcon}
		</button>
	)
}
