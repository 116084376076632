import React from 'react'
import './style.css'
import PlusIcon from 'icons/PlusIcon'

export default function ShowMore(props) {

	const {
		page,
		setPage
	} = props
	const showMoreFunc = function () {
		setPage(page + 1);
	}

	return (
		<button
			className='our-cars-show-more'
			onClick={showMoreFunc}
		>
			<div className='our-cars-show-more__text'>
				<h5>
					Показать ещё
				</h5>
			</div>
			<div className='our-cars-show-more__icon'>
				<PlusIcon />
			</div>
		</button>
	)
}