import React from 'react';
import { YMaps, Map, GeoObject } from 'react-yandex-maps';
import './style.css'

export function MapBasics() {

	const mapState = { center: [55.661115, 37.562254], zoom: 15 };

	return (
		<YMaps>
			<div className='contacts__map-widget'>
				<Map state={mapState} width='100%' height='100%'>
					<GeoObject
						// The geometry description.
						geometry={{
							type: 'Point',
							coordinates: [55.661115, 37.562254],
						}}
						// Properties.
						properties={{
							// The placemark content.
							iconContent: '',
						}}
						// Options.
						options={{
							// The placemark's icon will stretch to fit its contents.
							preset: 'islands#blackStretchyIcon',
							// The placemark can be moved.
							draggable: true,
						}}
					/>
				</Map>
			</div>
		</YMaps>
	);
}

export default MapBasics;