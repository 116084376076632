import React, { useState, useCallback, useEffect } from 'react'
import Button from 'ui/Button/Button'
import './style.css'
import BackButtonIcon from 'icons/BackButtonArrow.jsx'
import { getCarById } from 'services/cars.services'
import { useParams, useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import DropList from 'ui/DropList/DropList'
import SliderThumbs from 'components/Sliders/SliderThumbs/SliderThumbs'

export function CarPageCarInfo(props) {

	const {
		setPopupData,
		setIsLoading
	} = props

	const { carId } = useParams();

	const navigate = useNavigate();

	const [carData, setCarData] = useState([]);
	const [equipment, setEquipment] = useState([]);
	const getCarData = useCallback(debounce(async (id) => {
		setCarData([]);
		setIsLoading(true);
		try {
			const { data: result } = await getCarById(id);
			console.log(result);
			setCarData(result);
			let equipArr = [];
			if (result.safety !== null) {
				let equipObj = {
					data: result.safety,
					title: 'Безопасность'
				}
				equipArr.push(equipObj);
			}
			if (result.overview !== null) {
				let equipObj = {
					data: result.overview,
					title: 'Обзор'
				}
				equipArr.push(equipObj);
			}
			if (result.interior !== null) {
				let equipObj = {
					data: result.interior,
					title: 'Салон'
				}
				equipArr.push(equipObj);
			}
			if (result.comfort !== null) {
				let equipObj = {
					data: result.comfort,
					title: 'Комфорт'
				}
				equipArr.push(equipObj);
			}
			if (result.theft_protection !== null) {
				let equipObj = {
					data: result.theft_protection,
					title: 'Защита от угона'
				}
				equipArr.push(equipObj);
			}
			if (result.multimedia !== null) {
				let equipObj = {
					data: result.multimedia,
					title: 'Мультимедия'
				}
				equipArr.push(equipObj);
			}
			if (result.exterior !== null) {
				let equipObj = {
					data: result.exterior,
					title: 'Элементы экстерьера'
				}
				equipArr.push(equipObj);
			}
			setEquipment(equipArr);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}, 400), [])

	useEffect(() => {
		getCarData(carId);
	}, [])

	useEffect(() => {
		setPopupData(carData);
	}, [carData])




	return (
		<>
			<div className='car-page-top'>
				<Button
					title="Наши автомобили"
					variant="btn-back"
					leftIcon={<BackButtonIcon />}
					onClick={() => navigate(`/ourcars`)}
				/>
				<div className='car-page-top__title-and-cost'>
					<h1 className='car-page-top__title'>{carData?.model ? `${carData.mark.name} ${carData.model}` : ``}</h1>
					<h1 className='car-page-top__cost'>{carData?.price ? `${carData.price.toLocaleString()} ₽` : ''}</h1>
				</div>
			</div>
			<div className='car-page-car-info'>
				<div className='car-page-car-info__slider'>
					<div className='fill-block'>
						{carData?.images? <SliderThumbs data={carData.images} /> : ''}
					</div>
				</div>
				<div className='car-page-car-info__description'>
					{carData?.short_description && carData.short_description !== undefined ? <p>{carData.short_description}</p> : null}
					<div>
						<div className='row car-page-car-info__position'>
							<div className='col-xl-4 col-6 car-page-car-info__header'>
								Год:
							</div>
							<div className='col-xl-8 col-6 car-page-car-info__data'>
								{carData?.year ? carData.year : ''}
							</div>
						</div>
						<div className='row car-page-car-info__position'>
							<div className='col-xl-4 col-6 car-page-car-info__header'>
								Пробег:
							</div>
							<div className='col-xl-8 col-6 car-page-car-info__data'>
								{carData?.mileage ? `${carData.mileage.toLocaleString()} км` : ''}
							</div>
						</div>
						<div className='row car-page-car-info__position engine-row'>
							<div className='col-xl-4 col-6 car-page-car-info__header engine'>
								Двигатель:
							</div>
							<div className='col-xl-8 col-6 car-page-car-info__data engine-data'>
								<span>
									<span>{carData?.volume ? `${carData.volume} л` : ''}</span>
									<span>{carData?.power ? `${carData.power} л.с.` : ''}</span>
									<span>{carData?.engine?.fuel ? carData.engine.fuel : ''}</span>
								</span>
							</div>
						</div>
						<div className='row car-page-car-info__position'>
							<div className='col-xl-4 col-6 car-page-car-info__header'>
								Коробка:
							</div>
							<div className='col-xl-8 col-6 car-page-car-info__data'>
								{carData?.transmission?.name ? carData.transmission.name : ''}
							</div>
						</div>
						<div className='row car-page-car-info__position'>
							<div className='col-xl-4 col-6 car-page-car-info__header'>
								Привод:
							</div>
							<div className='col-xl-8 col-6 car-page-car-info__data'>
								{carData?.drive?.name ? carData.drive.name : ''}
							</div>
						</div>
						<div className='row car-page-car-info__position'>
							<div className='col-xl-4 col-6 car-page-car-info__header'>
								Владельцы:
							</div>
							<div className='col-xl-8 col-6 car-page-car-info__data'>
								{carData?.owners ? carData.owners : ''}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='car-page__equipment'>
				<h3>Комплектация</h3>
				<div className='row'>
					<div className='col-lg-6'>
						{equipment.map((item, index) => {
							if (index % 2 === 0) {
								return (<DropList
									name={item.title}
									data={item.data}
									key={index}
								/>
								)
							}
						})}
					</div>
					<div className='col-lg-6'>
					{equipment.map((item, index) => {
							if (index % 2 !== 0) {
								return (<DropList
									name={item.title}
									data={item.data}
									key={index}
								/>
								)
							}
						})}
					</div>

				</div>
			</div>
		</>
	)
}