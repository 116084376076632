import React from 'react'
import Button from 'ui/Button/Button'
import ArrowTopRightIcon from 'icons/ArrowTopRightIcon'
import './style.css'
import { useNavigate } from 'react-router-dom'

export function MainPageHeader() {

	const navigate = useNavigate();

	return (
		<div className='main-page-header'>
			<div className='main-page-header__title-block'>
				<h1>
					Автомобили c&nbsp;пробегом
				</h1>
				<div className='main-page-header__title-caption'>
					<div className='main-page-header__caption'>
						Задача команды &laquo;Автобриф&raquo;&nbsp;&mdash; удовлетворение всех потребностей и&nbsp;запросов автолюбителей от&nbsp;покупки автомобиля до&nbsp;дальнейшей его эксплуатации. Мы&nbsp;предлагаем продукцию высокого качества и&nbsp;высокий уровень сервиса.
					</div>
					<Button
						variant="btn-outline mobile-full-width"
						rightIcon={<ArrowTopRightIcon />}
						title="Наши автомобили"
						onClick={()=>navigate('/ourcars')}
					/>
				</div>
			</div>
			<div className='main-page-header__pic'>
				<img src="/img/header-image.jpg" alt="" />
			</div>
		</div>
	)
}

export function MainPageAboutCompany() {

	const navigate = useNavigate();

	return (
		<div className='main-page-about-company'>
			<div className='main-page-about-company__wrapper'>
				<div className='main-page-about-company__first'>
					<h2>О&nbsp;компании</h2>
					<div className='main-page-about-company__caption-first'>
						<p>Компания &laquo;Автобриф&raquo; предоставляет полный комплекс услуг: выкуп и&nbsp;trade-in, комиссионную продажу, тест-драйв, кредитование и&nbsp;лизинг, страхование, регистрации в&nbsp;ГИБДД.</p>
						<p>Все услуги оказываются командой высококвалифицированных и&nbsp;сертифицированных специалистов в&nbsp;сфере автобизнеса оперативно и&nbsp;качественно.</p>
					</div>
					<div className='main-page-about-company__pic-first'>
						<img src="/img/about-1.jpg" alt="" />
					</div>
				</div>
				<div className='main-page-about-company__second'>
					<div className='main-page-about-company__pic-second d-xl-block d-none'>
						<img src="/img/about-2.jpg" alt="" />
					</div>
					<div className='main-page-about-company__caption-second'>
						Каждый автомобиль:
						<ul className='mt-3'>
							<li>
								имеет техническое описание с&nbsp;необходимой калькуляцией
							</li>
							<li>
								сертификат безопасности осмотра Экспертно-Криминалистического Центра
							</li>
							<li>
								проходит необходимую предпродажную подготовку
							</li>
							<li>
								проходит тест-драйв перед покупкой
							</li>
						</ul>
					</div>
					<Button
						variant="btn-outline mobile-full-width"
						rightIcon={<ArrowTopRightIcon />}
						title="Наши автомобили"
						onClick={()=>navigate('/ourcars')}
					/>
				</div>
			</div>
		</div>
	)
}
