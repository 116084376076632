import React, { useState } from 'react'
import './style.css'
import Button from 'ui/Button/Button'
import PhoneIcon from 'icons/PhoneIcon'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export default function Header(props) {

	const {
		activeLink,
		...rest
	} = props
	// let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	const [winHeight, setWinHeight] = useState(null);
	window.addEventListener('resize', function () {
		setWinHeight(window.innerHeight);
		document.querySelector('#root').style.setProperty('--vh', winHeight * 0.01 + 'px');
	})
	useEffect(() => setWinHeight(window.innerHeight), [])

	const [switchBtn, setSwitchBtn] = useState(true);
	const [switchBtnClassName, setSwitchBtnClassName] = useState('header__switch d-lg-none d-block');
	const switchBtnClick = function () {
		setSwitchBtn(!switchBtn);
		if (switchBtn) {
			setSwitchBtnClassName('header__switch active d-lg-none d-block');
			document.querySelector('#root').classList.add('fixed-menu');
			document.querySelector('#root').style.setProperty('--vh', winHeight * 0.01 + 'px');
			document.querySelector('.header__menu-mobile').classList.add('active');
		} else {
			setSwitchBtnClassName('header__switch d-lg-none d-block');
			document.querySelector('#root').classList.remove('fixed-menu');
			document.querySelector('.header__menu-mobile').classList.remove('active');
		}
	}

	const checkFix = function () {
		if (document.querySelector('#root').classList.contains('fixed-menu')) {
			document.querySelector('#root').classList.remove('fixed-menu');
		} else {
		}
	}

	const navigate = useNavigate();

	return (
		<div
			className='header'
			{...rest}
		>
			<div className='header__wrapper'>
				<Link to="/">
					<img className='header__logo'
						src='/img/logo-black.svg'
						alt=""
					/>
				</Link>
				<div className='header__navigation d-none d-lg-flex'>
					<Button
						title="Главная"
						variant={`btn-underline ${activeLink === 1 ? 'active' : ''}`}
						onClick={() => {
							navigate(`/`);
							if (activeLink !== 1) {
								checkFix();
							}
							else {
								switchBtnClick();
							}
						}}
					/>
					<Button
						title="Наши автомобили"
						variant={`btn-underline ${activeLink === 2 ? 'active' : ''}`}
						onClick={() => {
							navigate(`/ourcars`);
							if (activeLink !== 2) {
								checkFix();
							} else {
								switchBtnClick();
							}
						}}
					/>
					<Button
						title="Контакты"
						variant={`btn-underline ${activeLink === 3 ? 'active' : ''}`}
						onClick={() => {
							navigate(`/contacts`);
							if (activeLink !== 3) {
								checkFix();
							} else {
								switchBtnClick();
							}
						}}
					/>
				</div>
				<a className='header__phone d-none d-lg-flex' href="tel:+74993837575">
					<PhoneIcon className="me-2" />
					<div className='header__number' >+7 499 383 75 75</div>
				</a>
				<div
					className={switchBtnClassName}
					onClick={switchBtnClick}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="header__switch-icon">
						<path fillRule="evenodd" clipRule="evenodd" d="M22 9H2V7H22V9Z" fill="black" />
						<path d="M22 17H2V15H22V17Z" fill="black" />
					</svg>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="header__switch-icon-active">
						<path fillRule="evenodd" clipRule="evenodd" d="M18.3639 19.7783L4.22177 5.63614L5.63599 4.22192L19.7781 18.3641L18.3639 19.7783Z" fill="black" />
						<path d="M4.22185 18.3639L18.364 4.22177L19.7782 5.63599L5.63606 19.7781L4.22185 18.3639Z" fill="black" />
					</svg>
				</div>
			</div>
			<div className='header__menu-mobile d-lg-none'>
				<div className='header__menu-mobile-links'>
					<Link
						to="/"
						onClick={() => {
							if (activeLink !== 1) {
								checkFix();
							}
							else {
								switchBtnClick();
							}
						}}
					>
						Главная
					</Link>
					<Link
						to="/ourcars"
						onClick={() => {
							if (activeLink !== 2) {
								checkFix();
							} else {
								switchBtnClick();
							}
						}}
					>
						Наши автомобили
					</Link>
					<Link
						to="/contacts"
						onClick={() => {
							if (activeLink !== 3) {
								checkFix();
							} else {
								switchBtnClick();
							}
						}}
					>
						Контакты
					</Link>
				</div>
				<div className='header__menu-mobile-footer'>
					<a className='header__phone' href="tel:+74993837575">
						<PhoneIcon className="me-2" />
						<div className='header__number' >+7 499 383 75 75</div>
					</a>
				</div>
			</div>
		</div >
	)
}
