import React from 'react'

export default function MinusIcon(props) {

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path d="M19 13H5V11H19V13Z" fill="#222222" />
		</svg>
	)
}
