import React from 'react'
import './style.css'
import TextLogo from 'icons/TextLogo'

export default function Footer() {

	const year = new Date().getFullYear();

	return (
		<div className='footer'>
			<div className='footer__logo-block'>
				<div className='footer__logo'>
					<img src='/img/logo-black.svg' alt="" />
				</div>
				<div className='footer__copyright d-none d-lg-block'>
					© {year} ООО Автобриф. Все права защищены
				</div>
				<a className='copyright d-none d-lg-flex' href="https://metasharks.ru/" target="__blank">
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" className='logo-mini'>
						<path d="M5.67544 0.242188L7.62637 0.849999L2.01005 25.8918L0 25.284L5.67544 0.242188Z" fill="#C97B00" />
						<path d="M16.6125 0.242188L18.5634 0.849999L12.9471 25.8918L10.937 25.284L16.6125 0.242188Z" fill="#C97B00" />
						<path d="M27.5495 0.242188L29.5004 0.849999L23.8841 25.8918L21.8741 25.284L27.5495 0.242188Z" fill="#C97B00" />
					</svg>
					<div className='copyright__text'>
						<TextLogo />
						<div className='copyright__hover-text'>Проектируем и&nbsp;разрабатываем<br />digital-решения</div>
					</div>
				</a>
			</div>
			<div className='footer__link-block'>
				<div className='footer__copyright d-lg-none'>
					© {year} ООО Автобриф. Все права защищены
				</div>
				<div className='copyright-wrap d-lg-none'>
					<a className='copyright' href="https://metasharks.ru/" target="__blank">
						<div className='copyright__text'>
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none" className='logo-mini'>
								<path d="M5.67544 0.242188L7.62637 0.849999L2.01005 25.8918L0 25.284L5.67544 0.242188Z" fill="#C97B00" />
								<path d="M16.6125 0.242188L18.5634 0.849999L12.9471 25.8918L10.937 25.284L16.6125 0.242188Z" fill="#C97B00" />
								<path d="M27.5495 0.242188L29.5004 0.849999L23.8841 25.8918L21.8741 25.284L27.5495 0.242188Z" fill="#C97B00" />
							</svg>
							<TextLogo />
						</div>
						<div className='copyright__hover-text'>Проектируем и&nbsp;разрабатываем digital-решения</div>
					</a>
				</div>
				<div className='footer__soc-block'>
					<a href="https://t.me/Autobrif" target='_blank'>
						Telegram
					</a>
					<a href="http://wa.me/74993837575" target='_blank'>
						Whatsapp
					</a>
				</div>
				<div className='footer__info-block'>
					<a href="tel:+74993837575">
						+7 499 383 75 75
					</a>
					<a href="mailto:info@autobrif.ru">
						info@autobrif.ru
					</a>
					<div className='footer__address'>
						117461, г.&nbsp;Москва, ул.&nbsp;Каховка, д.&nbsp;30
					</div>
				</div>
			</div>
		</div>
	)
}
