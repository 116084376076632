import axios from 'axios';
const API_URL = (process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_API_URL) || 'https://api.autobrif.msharks.ru/api';
// const ROOT_URL = process.env.REACT_APP_ROOT_URL || '/';
const REDIRECT_URL = process.env.REACT_APP_LOGIN_REDIRECT_URL || '/login/';
// const IS_DEV = process.env.REACT_APP_IS_DEV_MODE

export const axiosInstance = axios.create({
	baseURL: API_URL,
	responseType: 'json',
});

axiosInstance.interceptors.response.use(
	(response) => {
		if (response?.data?.token) {
			localStorage.setItem('token', response.data.token)
		}
		return response;
	},
	(error) => {
		if (error.response.status === 401) {
			window.location.replace(REDIRECT_URL)
		}
		throw error
	}
)

axiosInstance.interceptors.request.use(
	(config) =>
	  new Promise((resolve) => {
		const newConfig = {
		  headers: {},
		  ...config
		}

		const token = localStorage.getItem('token')
		if (token) {
			newConfig.headers['Authorization'] = 'Token ' + token
		}

		resolve(newConfig)
	  }),
	(e) => Promise.reject(e)
  )