import React from 'react'
import './style.css'

export default function Preloader() {
	return (
		<div className='preloader'>
			<div className='preloader__wrap'>
				<div className='preloader__animation'>
					<div className='preloader__animation-part preloader__animation-part-1'/>
					<div className='preloader__animation-part preloader__animation-part-2'/>
					<div className='preloader__animation-part preloader__animation-part-3'/>
					<div className='preloader__animation-part preloader__animation-part-4'/>
				</div>
			</div>
		</div>
	)
}
