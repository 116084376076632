import React, { useState } from 'react'
import MinusIcon from 'icons/MinusIcon'
import PlusIcon from 'icons/PlusIcon'
import './style.css'

export default function DropList(props) {
	const {
		name,
		data,
		children
	} = props

	const [droplistClass, setDroplistClass] = useState('droplist')

	const changeClass = function () {
		if (droplistClass === 'droplist') {
			setDroplistClass('droplist active');
		} else {
			setDroplistClass('droplist');
		}
	}

	return (
		<div className={droplistClass}>
			<div className='droplist__head' onClick={() => changeClass()}>
				<div className='droplist__name'>
					{name !== undefined ? name : ''}
				</div>
				<div className='droplist__icon'>
					<PlusIcon className='droplist__plus' />
					<MinusIcon className='droplist__minus' />
				</div>
			</div>
			{data !== undefined ? <div className='droplist__body' dangerouslySetInnerHTML={{ __html: data }} /> : ''}
			{children !== undefined ? <div className='droplist__body'>{children}</div> : ''}
		</div>
	)
}
