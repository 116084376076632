import SliderPopup from 'components/Sliders/SliderPopup/SliderPopup';
import CloseIcon from 'icons/CloseIcon';
import React from 'react'
import './style.css'

export default function Popup(props) {
	const {
		data,
		...rest
	} = props

	console.log(data);

	const closePopup = function () {
		if (document.querySelector('.popup').classList.contains('active')) {
			document.querySelector('#root').classList.remove('fixed');
			document.querySelector('#root').style.height = 'auto';
			document.querySelector('.popup').classList.remove('active');
		}
	}

	return (

		<div className='popup'>
			<div className='popup__wrap'>
				<div className='popup__slider'>
					{data.images ? <SliderPopup data={data.images} /> : null}
				</div>
				<div className='popup__info scrollbar-vertical'>
					<div
						className='popup__close'
						onClick={() => closePopup()}
					>
						<CloseIcon />
					</div>
					<div className='popup__info-head'>
						<div className='popup__info-price'>
							{data?.price ? `${data.price.toLocaleString()} ₽` : null}
						</div>
						<div className='popup__info-name'>
							{data?.model && data?.mark?.name ? `${data.mark.name} ${data.model}` : null}
						</div>
					</div>
					<div className='popup__info-body'>
						<div className='popup__info-item'>
							<div className='popup__info-item-title'>
								Год
							</div>
							<div className='popup__info-item-caption'>
								{data?.year ? data.year : null}
							</div>
						</div>
						<div className='popup__info-item'>
							<div className='popup__info-item-title'>
								Пробег
							</div>
							<div className='popup__info-item-caption'>
								{data?.mileage ? `${data.mileage.toLocaleString()} км` : ''}
							</div>
						</div>
						<div className='popup__info-item'>
							<div className='popup__info-item-title'>
								Двигатель
							</div>
							<div className='popup__info-item-caption'>
								{data?.volume && data?.power && data?.engine?.fuel ? `${data.volume} л | ${data.power} л.с. | ${data.engine.fuel}` : null}
							</div>
						</div>
						<div className='popup__info-item'>
							<div className='popup__info-item-title'>
								Коробка
							</div>
							<div className='popup__info-item-caption'>
								{data?.transmission?.name ? data.transmission.name : null}
							</div>
						</div>
						<div className='popup__info-item'>
							<div className='popup__info-item-title'>
								Привод
							</div>
							<div className='popup__info-item-caption'>
								{data?.drive?.name ? data.drive.name : null}
							</div>
						</div>
						<div className='popup__info-item'>
							<div className='popup__info-item-title'>
								Владельцы
							</div>
							<div className='popup__info-item-caption'>
								{data?.owners ? data.owners : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='popup-bg d-none d-lg-block'>
				<img src="/img/popup-bg.svg" alt="" />
			</div>
			<div className='popup-bg d-lg-none'>
				<img src="/img/popup-bg-mob.svg" alt="" />
			</div>
		</div>
	)
}
