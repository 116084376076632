import React, { useEffect } from 'react'
import Header from 'components/Header/Header'
import PageContainer from 'components/PageContainer/PageContainer'
import Footer from 'components/Footer/Footer'
import { MainPageHeader, MainPageAboutCompany } from './MainPageBlocks/MainPageBlocks'
import WhyWe from 'components/WhyWe/WhyWe'
import MainPageServices from './MainPageBlocks/MainPageServices'

export default function MainPage() {
	useEffect(() => window.scrollTo(0, 0), [])

	return (
		<PageContainer>
			<Header activeLink={1} />
			<MainPageHeader />
			<MainPageAboutCompany />
			<WhyWe />
			<MainPageServices />
			<Footer />
		</PageContainer>
	)
}
