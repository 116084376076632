import React from 'react'

export default function PlusIcon(props) {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M25.3334 17.3334H17.3334V25.3334H14.6667V17.3334H6.66675V14.6667H14.6667V6.66675H17.3334V14.6667H25.3334V17.3334Z" fill="#222222" />
		</svg>

	)
}
