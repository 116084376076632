import React, { useEffect } from 'react'

import './style.css'
import Button from 'ui/Button/Button'
import ArrowTopRightIcon from 'icons/ArrowTopRightIcon'
import Slider from 'components/Sliders/Slider/Slider'
import SliderMobile from 'components/Sliders/SliderMobile/SliderMobile'
import { useNavigate } from 'react-router-dom'

export default function CarCard(props) {

	const {
		data,
		...rest
	} = props;

	const navigate = useNavigate();
	const carId = data.id;

	let sliderList = [];

	if (data?.images) {
		data.images.map(item => sliderList.push(item));
	}

	return (
		<div
			className='car-card'
			{...rest}
			onClick={() => navigate(`/cars/${carId}`)}
		>
			<Slider>
				{sliderList}
			</Slider>
			<div className='car-card__slider-mobile-wrap'>
				<SliderMobile data={sliderList} />
			</div>
			<div className='car-card__info'>
				<h3 className='car-card__model'>
					{data && data?.mark?.name && data?.model ? `${data.mark.name} ${data.model}` : ''}
				</h3>
				<div className='car-card__description'>
					<div className='car-card__line car-card__year'>
						<div className='car-card__header'>
							Год:
						</div>
						<div className='car-card__table-data'>
							<span>
								{data && data?.year ? data.year : ''}
							</span>
						</div>
					</div>
					<div className='car-card__line car-card__mileage'>
						<div className='car-card__header'>
							Пробег:
						</div>
						<div className='car-card__table-data car-card__kilometers'>
							<span>
								{data && data?.mileage ? `${data.mileage.toLocaleString()} км` : ''}
							</span>
						</div>
					</div>
					<div className='car-card__line car-card__owners'>
						<div className='car-card__header'>
							Владельцы:
						</div>
						<div className='car-card__table-data'>
							<span>
								{data && data?.owners ? data.owners : ''}
							</span>
						</div>
					</div>
					<div className='car-card__line car-card__engine'>
						<div className='car-card__header'>
							Двигатель:
						</div>
						<div className='car-card__table-data'>
							<span>
								<span>
									{data && data?.volume ? `${data.volume} л` : ''}
								</span>
								<span className='span-with-before'>
									{data && data?.power ? `${data.power} л.с.` : ''}
								</span>
								<span className='span-with-before'>
									{data && data?.engine?.fuel ? data.engine.fuel : ''}
								</span>
							</span>
						</div>
					</div>
					<div className='car-card__line car-card__transmission'>
						<div className='car-card__header'>
							Коробка:
						</div>
						<div className='car-card__table-data'>
							<span>
								{data && data?.transmission?.name ? data.transmission.name : ''}
							</span>
						</div>
					</div>
					<div className='car-card__line car-card__drive-unit'>
						<div className='car-card__header'>
							Привод:
						</div>
						<div className='car-card__table-data'>
							<span>
								{data && data?.drive?.name ? data.drive.name : ''}
							</span>
						</div>
					</div>
				</div>
				<div className='car-card__bottom'>
					<h3>
						{data && data?.price ? `${data.price.toLocaleString()} ₽` : null}
					</h3>
					<Button
						variant="btn-outline"
						rightIcon={<ArrowTopRightIcon />}
						title="Подробнее"
					/>
				</div>
			</div>
		</div>
	)
}