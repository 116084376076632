import ChevronDownIcon from 'icons/ChevronDownIcon'
import React, { useState } from 'react'
import './style.css'

export default function Dropdown(props) {

	const {
		setSortType,
		...rest
	} = props

	const [title, setTitle] = useState(null)
	const [dropdownClasses, setDropdownClasses] = useState('dropdown')
	const [dropdownListClasses, setDropdownListClasses] = useState('dropdown__list')

	const dropdownListClassFunc = function () {
		if (dropdownListClasses === 'dropdown__list') {
			setDropdownListClasses('dropdown__list active');
		} else {
			setDropdownListClasses('dropdown__list');
		}
		if (dropdownClasses === 'dropdown') {
			setDropdownClasses('dropdown active');
		} else {
			setDropdownClasses('dropdown');
		}
	}

	const dropdownListItems = [
		{
			label: 'По умолчанию',
			sort: 'sort_index',
			direction: 'asc'
		},
		{
			label: 'По году: старше',
			sort: 'year',
			direction: 'asc'
		},
		{
			label: 'По году: новее',
			sort: 'year',
			direction: 'desc'
		},
		{
			label: 'По возрастанию цены',
			sort: 'price',
			direction: 'asc'
		},
		{
			label: 'По убыванию цены',
			sort: 'price',
			direction: 'desc'
		},
	]

	return (
		<div
			className={dropdownClasses}
			{...rest}
			onClick={() => dropdownListClassFunc()}
		>
			{title !== null ? (<span className='dropdown__title'>{title}</span>) : (<span className='dropdown__title-default'>Сортировка</span>)}
			<ChevronDownIcon />
			<div className={dropdownListClasses}>
				{dropdownListItems.map((item, index) =>
					<div
						className='dropdown__list-item'
						value={item.label}
						key={index}
						onClick={() => {
							setTitle(item.label);
							setSortType({
								sort: item.sort,
								direction: item.direction
							})
						}}
					>
						{item.label}
					</div>)}
			</div>
		</div>
	)
}
