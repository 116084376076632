import React from 'react'
import DropList from 'ui/DropList/DropList'

export default function MainPageServices() {
	return (
		<div className='main-page-services'>
			<h2>Услуги</h2>
			<div className='row'>
				<div className='col-lg-6 first-block'>
					<DropList name="Выкуп авто">
						<div className='main-page-services__body'>
							<div className='main-page-services__img'>
								<div className='main-page-services__img-wrap'>
									<img src='/img/main-page-buyout.jpg' alt='' />
								</div>
							</div>
							<div className='main-page-services__caption'>
								Выкуп всех видов транспортных средств за&nbsp;наличный и&nbsp;безналичный расчёт у&nbsp;физических и&nbsp;юридических лиц, выкуп с&nbsp;НДС и&nbsp;без НДС
							</div>
						</div>
					</DropList>
					<DropList name="Trade in">
						<div className='main-page-services__body'>
							<div className='main-page-services__img'>
								<div className='main-page-services__img-wrap'>
									<img src='/img/main-services-1.jpg' alt='' />
								</div>
							</div>
							<div className='main-page-services__caption'>
								Обмен старого автомобиля в&nbsp;счёт покупки нового с&nbsp;возможность доплаты в&nbsp;любую сторону
							</div>
						</div>
					</DropList>
					<DropList name="Комиссионная продажа">
						<div className='main-page-services__body'>
							<div className='main-page-services__img'>
								<div className='main-page-services__img-wrap'>
									<img src='/img/main-page-commission-sale.jpg' alt='' />
								</div>
							</div>
							<div className='main-page-services__caption'>
								Выполнение предпродажной подготовки с&nbsp;последующей реализацией авто
							</div>
						</div>
					</DropList>
				</div>
				<div className='col-lg-6 second-block'>
					<DropList name="Финансовые услуги">
						<div className='main-page-services__body'>
							<div className='main-page-services__img'>
								<div className='main-page-services__img-wrap'>
									<img src='/img/main-page-financial-services.jpg' alt='' />
								</div>
							</div>
							<div className='main-page-services__caption'>
								Возможность приобретения автомобиля по&nbsp;различным кредитным и&nbsp;лизинговым программам
							</div>
						</div>
					</DropList>
					<DropList name="Страхование авто">
						<div className='main-page-services__body'>
							<div className='main-page-services__img'>
								<div className='main-page-services__img-wrap'>
									<img src='/img/main-page-auto-insurance.jpg' alt='' />
								</div>
							</div>
							<div className='main-page-services__caption'>
								Подбор и&nbsp;оформление всех видов полисов на&nbsp;лучших условиях от&nbsp;страховых компаний-партнеров
							</div>
						</div>
					</DropList>
				</div>
			</div>
		</div>
	)
}
