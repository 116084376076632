import React, { useEffect } from 'react'
import Header from 'components/Header/Header'
import PageContainer from 'components/PageContainer/PageContainer'
import Footer from 'components/Footer/Footer'
import Contacts from './ContactsPageBlocks/ContactsPageBlocks'

export default function ContactsPage() {
	useEffect(() => window.scrollTo(0, 0), [])
	return (
		<PageContainer>
			<Header activeLink={3} />
			<Contacts />
			<Footer />
		</PageContainer>
	)
}