import React from 'react'

export default function TextLogo() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="162" height="21" viewBox="0 0 162 21" fill="none" className='text-logo'>
			<path d="M12.2511 19.6704V9.7023C12.2511 9.0337 12.1329 8.48667 11.8373 8.12199C11.5417 7.69652 11.0687 7.51417 10.4775 7.51417C9.88634 7.51417 9.41339 7.7573 8.99955 8.18277C8.64484 8.66902 8.46748 9.33761 8.46748 10.3101V19.7312H6.33919V9.58073C6.33919 8.97292 6.16183 8.42589 5.86624 8.0612C5.51152 7.63574 5.09769 7.45339 4.56562 7.45339C3.91531 7.45339 3.38324 7.69652 3.02852 8.18277C2.67381 8.66902 2.49645 9.27683 2.49645 10.067V19.6704H0.368164V5.75152H2.49645V7.27105H2.79205C3.02852 6.60246 3.38324 6.11621 3.85619 5.8123C4.32915 5.5084 4.86122 5.32605 5.45241 5.32605C6.10272 5.32605 6.63479 5.5084 7.04862 5.87308C7.46246 6.23777 7.75805 6.66324 7.93541 7.27105H8.29013C8.88132 5.99465 9.88634 5.38683 11.2461 5.38683C12.2511 5.38683 13.0197 5.69074 13.6108 6.35933C14.1429 7.02793 14.4385 7.87886 14.4385 8.97292V19.6704H12.2511Z" fill="#2A2A33" />
			<path d="M20.7643 13.4099C20.8234 14.8079 21.2372 15.902 22.0058 16.7529C22.7743 17.6038 23.8976 18.0293 25.2573 18.0293C25.9076 18.0293 26.4397 17.9685 26.9127 17.7862C27.3856 17.6646 27.7994 17.4215 28.1542 17.1784C28.5089 16.9353 28.7454 16.6313 28.9818 16.2667C29.2183 15.902 29.3365 15.5373 29.5139 15.1726L31.524 15.7196C31.1692 16.996 30.4598 18.0293 29.3957 18.8802C28.3906 19.6704 26.9718 20.0959 25.2573 20.0959C24.3114 20.0959 23.4246 19.9135 22.597 19.5488C21.7693 19.1842 21.119 18.6979 20.5278 18.0901C19.9366 17.4823 19.4636 16.6921 19.1681 15.8412C18.8133 14.9903 18.6951 14.0178 18.6951 12.9237V12.2551C18.6951 11.2826 18.8725 10.3709 19.2272 9.51995C19.5819 8.66901 20.0548 7.93964 20.646 7.33183C21.2372 6.72402 21.9467 6.23777 22.7743 5.93386C23.602 5.56918 24.4297 5.38683 25.3756 5.38683C26.4988 5.38683 27.4447 5.56918 28.2724 5.99464C29.1001 6.42011 29.7504 6.90636 30.2825 7.57495C30.8145 8.24355 31.1692 8.85136 31.4057 9.58073C31.6422 10.3101 31.7604 10.9787 31.7604 11.5865V13.3492H20.7643V13.4099ZM25.3164 7.45339C24.7253 7.45339 24.1341 7.57495 23.6611 7.7573C23.129 7.93964 22.7152 8.24355 22.3014 8.60823C21.8875 8.97292 21.5919 9.39839 21.3555 9.88464C21.119 10.3709 20.9416 10.8571 20.8825 11.4649H29.573C29.5139 10.8571 29.3957 10.3101 29.1592 9.82386C28.9227 9.33761 28.568 8.91214 28.2133 8.60823C27.7994 8.24355 27.3856 8.00042 26.8535 7.81808C26.3215 7.57495 25.8485 7.45339 25.3164 7.45339Z" fill="#2A2A33" />
			<path d="M34.2434 5.75151H38.5V0.0380859H40.6283V5.75151H45.8308V7.75729H40.6283V16.7529C40.6283 17.2999 40.8648 17.6038 41.3377 17.6038H45.1805V19.6096H40.6283C40.0371 19.6096 39.505 19.4273 39.0912 19.0018C38.6774 18.5763 38.5 18.0901 38.5 17.4215V7.81807H34.2434V5.75151Z" fill="#2A2A33" />
			<path d="M60.6106 17.2999H60.2558C59.842 18.2117 59.1917 18.941 58.4232 19.3665C57.6546 19.792 56.8269 20.0351 55.9993 20.0351C55.1125 20.0351 54.2848 19.8527 53.5163 19.5488C52.7477 19.2449 52.0974 18.7587 51.5062 18.1509C50.915 17.5431 50.5012 16.8137 50.1465 15.902C49.7918 15.051 49.6735 14.0178 49.6735 12.8629V12.4374C49.6735 11.2826 49.8509 10.3101 50.1465 9.39838C50.5012 8.54745 50.915 7.75729 51.5062 7.14948C52.0974 6.54167 52.7477 6.05542 53.5163 5.75151C54.2848 5.44761 55.1125 5.26526 55.9401 5.26526C56.8861 5.26526 57.7137 5.44761 58.4823 5.87308C59.2508 6.29854 59.842 6.96714 60.2558 7.81807H60.6106V5.75151H62.7388V16.8137C62.7388 17.3607 62.9753 17.6646 63.4483 17.6646H64.1577V19.6704H62.7388C62.1477 19.6704 61.6156 19.4881 61.2018 19.0626C60.7879 18.6371 60.6106 18.1509 60.6106 17.4823V17.2999ZM56.2357 17.9685C56.8861 17.9685 57.4772 17.847 58.0093 17.6038C58.5414 17.3607 59.0143 16.996 59.3691 16.5706C59.7238 16.1451 60.0785 15.5981 60.2558 14.9903C60.4923 14.3824 60.6106 13.6531 60.6106 12.9237V12.559C60.6106 11.8296 60.4923 11.1003 60.2558 10.4924C60.0194 9.88463 59.7238 9.3376 59.3691 8.91213C58.9552 8.48667 58.5414 8.12198 58.0093 7.87885C57.4772 7.63573 56.8861 7.51417 56.2949 7.51417C55.6446 7.51417 55.0534 7.63573 54.5804 7.87885C54.0483 8.12198 53.5754 8.48667 53.2207 8.91213C52.8068 9.3376 52.5112 9.88463 52.3339 10.4924C52.0974 11.1003 51.9792 11.8296 51.9792 12.559V12.9845C51.9792 14.5648 52.393 15.7804 53.1616 16.6921C53.9301 17.5431 54.9942 17.9685 56.2357 17.9685Z" fill="#2A2A33" />
			<path d="M70.8973 9.33761C70.8973 10.0062 71.1929 10.5532 71.725 10.8571C72.257 11.2218 73.262 11.4649 74.74 11.5865C76.3362 11.7081 77.5186 12.1335 78.3463 12.7414C79.174 13.4099 79.5878 14.3217 79.5878 15.5373V15.7196C79.5878 16.3882 79.4696 16.996 79.174 17.5431C78.9375 18.0901 78.5237 18.5156 78.1098 18.8802C77.6369 19.2449 77.1048 19.5488 76.3954 19.7312C75.7451 19.9135 75.0356 20.0351 74.208 20.0351C73.1438 20.0351 72.257 19.9135 71.4885 19.6096C70.7199 19.3057 70.1287 18.941 69.5967 18.4548C69.1237 17.9685 68.7099 17.4215 68.4734 16.8137C68.2369 16.2059 68.0596 15.5981 68.0596 14.9903L70.0696 14.504C70.1879 15.5981 70.5426 16.449 71.252 17.0568C71.9614 17.7254 72.9073 18.0293 74.0897 18.0293C75.0947 18.0293 75.8633 17.847 76.5136 17.4215C77.1048 16.996 77.4004 16.449 77.4004 15.7196C77.4004 14.9295 77.1048 14.3824 76.5136 14.0178C75.9224 13.6531 74.9174 13.4707 73.6168 13.3492C72.0797 13.2276 70.8382 12.8629 70.0105 12.2551C69.1828 11.6473 68.769 10.6748 68.769 9.45917V9.27682C68.769 8.60823 68.8872 8.0612 69.1828 7.57495C69.4784 7.0887 69.8331 6.66324 70.3061 6.35933C70.779 5.99464 71.3111 5.75152 71.8432 5.62996C72.4344 5.44761 73.0256 5.38683 73.6759 5.38683C74.5627 5.38683 75.3312 5.50839 76.0406 5.75152C76.691 5.99464 77.2822 6.35933 77.7551 6.72402C78.2281 7.14949 78.5828 7.57495 78.8193 8.12198C79.0557 8.66901 79.2331 9.15526 79.2922 9.70229L77.2822 10.1885C77.1639 9.27682 76.8683 8.60823 76.218 8.0612C75.6268 7.57495 74.7991 7.33183 73.8532 7.33183C73.4985 7.33183 73.0847 7.39261 72.73 7.45339C72.3753 7.51417 72.0797 7.63573 71.7841 7.81808C71.4885 8.00042 71.3111 8.18277 71.1338 8.42589C70.9564 8.7298 70.8973 9.0337 70.8973 9.33761Z" fill="#2A2A33" />
			<path d="M84.7312 19.6704V0.0380859H86.8595V8.24354H87.2142C87.628 7.33183 88.2192 6.66323 88.9878 6.1162C89.7563 5.56917 90.6431 5.32605 91.7663 5.32605C93.2443 5.32605 94.4267 5.8123 95.3726 6.84558C96.3185 7.81808 96.7323 9.3376 96.7323 11.3434V19.6096H94.6041V11.8296C94.6041 10.3709 94.3085 9.27682 93.6582 8.54745C93.0079 7.81807 92.1802 7.45339 91.0569 7.45339C89.7563 7.45339 88.7513 7.87886 88.0418 8.79057C87.3324 9.70229 86.9186 10.9179 86.9186 12.4374V19.6704H84.7312Z" fill="#2A2A33" />
			<path d="M111.217 17.2999H110.862C110.448 18.2117 109.798 18.941 109.029 19.3665C108.261 19.792 107.433 20.0351 106.605 20.0351C105.718 20.0351 104.891 19.8527 104.122 19.5488C103.354 19.2449 102.703 18.7587 102.112 18.1509C101.521 17.5431 101.107 16.8137 100.752 15.902C100.398 15.051 100.28 14.0178 100.28 12.8629V12.4374C100.28 11.2826 100.457 10.3101 100.752 9.39838C101.107 8.54745 101.521 7.75729 102.112 7.14948C102.703 6.54167 103.354 6.05542 104.122 5.75151C104.891 5.44761 105.718 5.26526 106.546 5.26526C107.492 5.26526 108.32 5.44761 109.088 5.87308C109.857 6.29854 110.448 6.96714 110.862 7.81807H111.217V5.75151H113.345V16.8137C113.345 17.3607 113.581 17.6646 114.054 17.6646H114.764V19.6704H113.345C112.754 19.6704 112.222 19.4881 111.808 19.0626C111.394 18.6371 111.217 18.1509 111.217 17.4823V17.2999ZM106.842 17.9685C107.492 17.9685 108.083 17.847 108.615 17.6038C109.147 17.3607 109.62 16.996 109.975 16.5706C110.33 16.1451 110.684 15.5981 110.862 14.9903C111.098 14.3824 111.217 13.6531 111.217 12.9237V12.559C111.217 11.8296 111.098 11.1003 110.862 10.4924C110.625 9.88463 110.33 9.3376 109.975 8.91213C109.561 8.48667 109.147 8.12198 108.615 7.87885C108.083 7.63573 107.492 7.51417 106.901 7.51417C106.251 7.51417 105.659 7.63573 105.186 7.87885C104.654 8.12198 104.181 8.48667 103.827 8.91213C103.413 9.3376 103.117 9.88463 102.94 10.4924C102.703 11.1003 102.585 11.8296 102.585 12.559V12.9845C102.585 14.5648 102.999 15.7804 103.827 16.6921C104.536 17.5431 105.541 17.9685 106.842 17.9685Z" fill="#2A2A33" />
			<path d="M118.488 5.75152H123.395V7.57495H123.75C124.105 6.7848 124.577 6.23777 125.228 5.87308C125.878 5.5084 126.588 5.32605 127.415 5.32605C128.775 5.32605 129.898 5.8123 130.726 6.72402C131.554 7.63574 132.026 8.97292 132.086 10.7356L129.839 11.1003C129.839 9.82386 129.543 8.91214 128.952 8.30433C128.361 7.7573 127.593 7.45339 126.765 7.45339C126.174 7.45339 125.701 7.57495 125.287 7.81808C124.873 8.0612 124.518 8.36511 124.282 8.7298C123.986 9.09448 123.809 9.58073 123.691 10.1278C123.572 10.6748 123.513 11.2218 123.513 11.8296V17.7254H126.942V19.7312H118.311V17.7254H121.444V7.81808H118.666V5.75152H118.488Z" fill="#2A2A33" />
			<path d="M138.234 11.5257H138.707L143.85 5.75151H146.452V5.93386L140.835 12.3159V12.8021L147.102 19.4881V19.6704H144.501L138.766 13.5315H138.234V19.6704H136.106V0.0380859H138.234V11.5257Z" fill="#2A2A33" />
			<path d="M153.309 9.33761C153.309 10.0062 153.605 10.5532 154.137 10.8571C154.669 11.2218 155.674 11.4649 157.152 11.5865C158.748 11.7081 159.931 12.1335 160.758 12.7414C161.586 13.4099 162 14.3217 162 15.5373V15.7196C162 16.3882 161.882 16.996 161.586 17.5431C161.35 18.0901 160.936 18.5156 160.522 18.8802C160.049 19.2449 159.517 19.5488 158.807 19.7312C158.157 19.9135 157.448 20.0351 156.62 20.0351C155.556 20.0351 154.669 19.9135 153.901 19.6096C153.132 19.3057 152.541 18.941 152.009 18.4548C151.536 17.9685 151.122 17.4215 150.885 16.8137C150.649 16.2059 150.472 15.5981 150.472 14.9903L152.482 14.504C152.6 15.5981 152.955 16.449 153.664 17.0568C154.374 17.7254 155.319 18.0293 156.502 18.0293C157.507 18.0293 158.275 17.847 158.926 17.4215C159.517 16.996 159.812 16.449 159.812 15.7196C159.812 14.9295 159.517 14.3824 158.926 14.0178C158.334 13.6531 157.329 13.4707 156.029 13.3492C154.492 13.2276 153.25 12.8629 152.423 12.2551C151.595 11.6473 151.181 10.6748 151.181 9.45917V9.27682C151.181 8.60823 151.299 8.0612 151.595 7.57495C151.891 7.0887 152.245 6.66324 152.718 6.35933C153.191 5.99464 153.723 5.75152 154.255 5.62996C154.846 5.44761 155.438 5.38683 156.088 5.38683C156.975 5.38683 157.743 5.50839 158.453 5.75152C159.103 5.99464 159.694 6.35933 160.167 6.72402C160.64 7.14949 160.995 7.57495 161.231 8.12198C161.468 8.66901 161.645 9.15526 161.704 9.70229L159.694 10.1885C159.576 9.27682 159.28 8.60823 158.63 8.0612C158.039 7.57495 157.211 7.33183 156.265 7.33183C155.911 7.33183 155.497 7.39261 155.142 7.45339C154.787 7.51417 154.492 7.63573 154.196 7.81808C153.901 8.00042 153.723 8.18277 153.546 8.42589C153.428 8.7298 153.309 9.0337 153.309 9.33761Z" fill="#2A2A33" />
		</svg>
	)
}
