import React, { useState, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { Mousewheel, FreeMode, Navigation, Thumbs, Pagination } from "swiper";

export default function SliderPopup({ data }) {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	// const sliderList = document.querySelectorAll('.slider-popup__preview .swiper-slide');
	// for (let i = 0; i < sliderList.length; i++) {
	// 	sliderList[i].addEventListener('mouseover', function () {
	// 		this.click();
	// 	})
	// }
	let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	const [sliderTop, setSliderTop] = useState();

	useEffect(() => {
		if (document.querySelector('.slider-thumbs__slider .swiper-slide')) {
			if (iOS) {
				setSliderTop((window.innerHeight - document.querySelector('.slider-thumbs__slider .swiper-slide').offsetHeight) / 2 - 72 - 60);
			} else {
				setSliderTop((window.innerHeight - document.querySelector('.slider-thumbs__slider .swiper-slide').offsetHeight) / 2 - 72);
			}
		}
	}, [])

	// useEffect(() => {
	// 	console.log(sliderTop);
	// }, [sliderTop])

	window.addEventListener('resize', function () {
		if (document.querySelector('.slider-thumbs__slider .swiper-slide')) {
			if (iOS) {
				setSliderTop((window.innerHeight - document.querySelector('.slider-thumbs__slider .swiper-slide').offsetHeight) / 2 - 72 - 60);
			} else {
				setSliderTop((window.innerHeight - document.querySelector('.slider-thumbs__slider .swiper-slide').offsetHeight) / 2 - 72);
			}
		}
	})

	const slider = useRef(null);

	return (
		<div className='slider-popup'>
			<div className='slider-popup__preview-wrap'>
				<Swiper
					onSwiper={setThumbsSwiper}
					spaceBetween={16}
					slidesPerView='auto'
					freeMode={true}
					navigation={true}
					direction={"vertical"}
					watchSlidesProgress={true}
					modules={[FreeMode, Navigation, Thumbs]}
					className="slider-popup__preview"
				>
					{data.map((item, index) =>
						<SwiperSlide
							key={item.id}
							onClick={(e) => {
								slider.current.swiper.slideTo(index);
							}}
						>
							<img src={item.url} alt="" />
						</SwiperSlide>
					)}
				</Swiper>
			</div>
			<div className='swiper-popup__wrap'>
				<div className='swiper-popup__wrap-inside'>
					<Swiper
						spaceBetween={10}
						thumbs={{ swiper: thumbsSwiper }}
						modules={[FreeMode, Navigation, Thumbs, Mousewheel, Pagination]}
						className="slider-popup__slider"
						ref={slider}
						navigation={true}
						pagination={{
							type: "fraction",
						}}
						mousewheel={true}
						breakpoints={{
							992: {
								direction: "vertical",
								navigation: false,
								pagination: false,
							}
						}}

						style={{ '--top': `${sliderTop}px` }}
					>
						{data.map(item =>
							<SwiperSlide key={item.id}>
								<img src={item.url} alt="" />
							</SwiperSlide>
						)}
					</Swiper>
				</div>
			</div>
		</div >
	);
}
