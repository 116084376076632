import React, { useState, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./style.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

export default function SliderThumbs({ data }) {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const sliderList = document.querySelectorAll('.slider-thumbs__preview .swiper-slide');
	for (let i = 0; i < sliderList.length; i++) {
		sliderList[i].addEventListener('mouseover', function () {
			this.click();
		})
	}

	const slider = useRef(null);
	const [winHeight, setWinHeight] = useState(null);
	window.addEventListener('resize', function () {
		setWinHeight(window.innerHeight);
		document.querySelector('#root').style.setProperty('--vh', winHeight * 0.01 + 'px');
	})
	useEffect(() => setWinHeight(window.innerHeight), [])

	const openPopup = function () {
		if (!document.querySelector('.popup').classList.contains('active')) {
			document.querySelector('#root').classList.add('fixed');
			document.querySelector('#root').style.setProperty('--vh', winHeight * 0.01 + 'px');
			document.querySelector('.popup').classList.add('active');
		}
	}

	return (
		<div className='slider-thumbs'>
			<Swiper
				spaceBetween={10}
				thumbs={{ swiper: thumbsSwiper }}
				modules={[FreeMode, Navigation, Thumbs]}
				className="slider-thumbs__slider"
				ref={slider}
			>
				{data.map(item =>
					<SwiperSlide
						key={item.id}
						onClick={() => openPopup()}
					>
						<img src={item.url} alt="" />
					</SwiperSlide>
				)}
			</Swiper>
			<div className='slider-thumbs__preview-wrapper'>
				<Swiper
					onSwiper={setThumbsSwiper}
					spaceBetween={18}
					slidesPerView={5}
					breakpoints={{
						1200: {
							slidesPerView: 3,
							spaceBetween: 16,
						},
					}}
					freeMode={true}
					navigation={true}
					direction={"vertical"}
					watchSlidesProgress={true}
					modules={[FreeMode, Navigation, Thumbs]}
					className="slider-thumbs__preview"
				>
					{data.map((item, index) =>
						<SwiperSlide
							key={item.id}
							onClick={(e) => {
								slider.current.swiper.slideTo(index);
							}}
						>
							<img src={item.url} alt="" />
						</SwiperSlide>
					)}
				</Swiper>
			</div>
		</div>
	);
}
