import React from 'react'
import Dropdown from 'ui/Dropdown/Dropdown'
import './style.css'

export default function OurCars(props) {

	const {
		setSortType,
	} = props;

	return (
		<div className='our-cars-header'>
			<div className='our-cars-header__title-block'>
				<h1>
					Наши автомобили
				</h1>
			</div>
			<Dropdown setSortType={setSortType} />
		</div>
	)
}